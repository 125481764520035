<template>
    <div class="input-group">
        <label class="control-label" :style="{width:labelWidth + 'px'}">
            <i v-if="asterisk" class="fa fa-asterisk"></i>{{label}}</label>
        <div class="input-date ">
            <input class="form-control" :disabled="startDisabled" type="text" :esapp-id="'start-' + id"  autocomplete ="off" />
            <span>-</span>
            <input class="form-control" :disabled="endDisabled" type="text" :esapp-id="'end-' + id"  autocomplete ="off" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            id: (Math.random()* 1000).toString(36).substring(7) + (Math.random()* 1000).toString(36).substring(7), // 修复当两个时间组件放一起时会出现相同id的问题
            datePulg: null,
        };
    },
    props: {
        label: {
            type: String,
            default: "做箱时间"
        },
        labelWidth: {
            type: Number,
            default: 80
        },
        start: {
            type: String,
            default: ""
        },
        end: {
            type: String,
            default: ""
        },
        startDisabled: {
            type: Boolean,
            default: false
        },
        endDisabled: {
            type: Boolean,
            default: false
        },
        asterisk: {
            type: Boolean,
            default: false
        },
        init: {
            type: Boolean,
            default: true
        },
        fixSet: {
            type: String,
            default: ''
        }
    },
    mounted: function() {
        //因为需要dom渲染完在初始化日期插件
        this.initPlug();
    },
    methods: {
        initPlug: function() {
            var that = this;
            // esWidget 需要引入widgit.js文件
            var datePulg = esWidget.esDateToDate({
                startId: "start-" + this.id,
                endId: "end-" + this.id,
                format: "yyyy-mm-dd",
                getAddTime: true,
                time: false,
                start_event_chage: function(res) {
                    // if(res){
                    //     res += " 00:00:00";
                    // }
                    if(that.fixSet !== "fixSet") {
                        if (!res) {
                            that.end = "";
                        }
                    }
                    that.start = res;
                    if(that.init) {
                        that.timeChanged();
                    }
                },
                end_event_chage: function(res) {
                    if(that.fixSet !== "fixSet") {
                        if (!res) {
                            that.start = "";
                        }
                    }
                    that.end = res;
                    if(that.init) { 
                        that.timeChanged();
                    }
                   
                }
            });
            datePulg.setValue({
                startDate: this.start,
                endDate: this.end
            });
            that.datePulg = datePulg;
            // esWidget 需要引入widgit.js文件
        },
        clearValue: function() {
            this.datePulg.clearValue();
        },
        setValue: function(val) {
            var date = {
                startDate: "",
                endDate: ""
            };
            if (val && val.start && val.end) {
                date = {
                    startDate: val.start.split(" ")[1]
                        ? val.start.split(" ")[0]
                        : val.start,
                    endDate: val.end.split(" ")[1]
                        ? val.end.split(" ")[0]
                        : val.end
                };
            } else if (val && val.end) {
                date = {
                    startDate: "",
                    endDate: val.end.split(" ")[1]
                        ? val.end.split(" ")[0]
                        : val.end
                };
            }
            this.datePulg.setValue(date);
        },
        timeChanged: function(whichTime, time) {
            this.$emit("change", {
                start: this.start,
                end: this.end
            });
        }
    }
};
</script>