// title 
//     名字
// value
//     默认值，五天中的一天    
<template>
  <div
    :class="{'input-group':checkWhick!=null}"
    class="select-day-of-five"
    style="cursor: pointer;display: inline-block;float: left;"
  >
    <label class="control-label" v-if="checkWhick!=null">{{title}}</label>
    <div v-if="checkWhick!=null" class="five-day-warp" style="width:auto">
      <span :class="{isChecked:checkWhick==-2}" @click="nowDateOfOther(-2)">前天</span>
      <span :class="{isChecked:checkWhick==-1}" @click="nowDateOfOther(-1)">昨天</span>
      <span :class="{isChecked:checkWhick==0}" @click="nowDateOfOther(0)">今天</span>
      <span :class="{isChecked:checkWhick==1}" @click="nowDateOfOther(1)">明天</span>
      <span :class="{isChecked:checkWhick==2}" @click="nowDateOfOther(2)">后天</span>
    </div>
    <template v-else>
      <dk-planarrive label='客戶要求做箱时间' @change="dateChange" ref="planarrive" v-show="isRange"></dk-planarrive>
      <dk-date label='客戶要求做箱时间' @change="dateChange" ref="datePicker" v-show="!isRange"></dk-date>
    </template>
    <span v-if="checkWhick!=null" class="over-five" @click="switchDateTypeToMore">更多</span>
    <span v-else class="over-five" @click="switchDateTypeToFive">返回</span>
  </div>
</template>

<script>
import dkDate from "dk-date";
import dkDateOnly from "dk-date-only-date";
export default {
  name: "dk-select-date-of-five",
  data() {
    return {
      nowDate: "",
      checkWhick: null,
      emitValue: ""
    };
  },
  props: {
    time: {
      type: Object,
      default: {t1: 'false'}
    },
    value: {
      type: null,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    // 是否区间选择
    isRange: {
      type: Boolean,
      default: true
    }
    // labelWidth: {
    //     type: Number,
    //     default: 85
    // },
    // isDisabled: {
    //     type: Boolean,
    //     default: false
    // },
    // isMustFill: {
    //     type: Boolean,
    //     default: false
    // }
  },
  components: {
    "dk-planarrive": dkDate,
    "dk-date": dkDateOnly
  },
  watch: {
    value: function() {
      this.getNow();
    }
  },

  mounted: function() {
    if (this.value) {
      this.getNow();
    }
  },
  methods: {
    setRange(start, end) {
      setTimeout(() =>{
        this.checkWhick = null;
        this.$nextTick(() => {
          this.$refs.planarrive.setValue({
            start: start,
            end: end
          })
        })
      },500)
    },
    dateChange: function(val) {
      this.timeChange(val.start, val.end);
    },
    switchDateTypeToMore: function() {
      this.checkWhick = null;
      this.$nextTick(() => {
        this.$refs.planarrive.setValue({
          start: this.time.t2,
          end: this.time.t1
        })
        if (this.time.t1 == 'false') {
          this.timeChange("", "");
        } else {
          this.timeChange(this.time.t2, this.time.t1);
        }
      })
    },
    switchDateTypeToFive: function() {
      this.checkWhick = 0;
      this.nowDateOfOther(0);
    },
    nowDateOfOther: function(day) {
      this.checkWhick = day;
      var millisecond = day * 24 * 60 * 60 * 1000;
      var now = new Date();
      var newDay = now.getTime() + millisecond;
      var newDay = new Date(newDay);
      var year = newDay.getFullYear();
      var month = this.leftPad(newDay.getMonth() + 1);
      var day = this.leftPad(newDay.getDate());
      this.nowDate = [year, month, day].join("-");
      this.timeChange(this.nowDate, this.nowDate, true);
    },
    getNow: function() {
      var now;
      if (this.value) {
        now = new Date(this.value);
      }
      var year = now.getFullYear();
      var month = this.leftPad(now.getMonth() + 1);
      var day = this.leftPad(now.getDate());
      this.nowDate = [year, month, day].join("-");
      var a = now.getTime();
      var b = new Date().getTime();
      var c = Math.round((a - b) / 24 / 60 / 60 / 1000);
      c = -2 <= c && c <= 2 ? c : null;
      console.log(c);
      this.checkWhick = c;
      this.$nextTick(() => {
        if (c == null) {
          setTimeout(() => {
            this.$refs.planarrive.setValue({
              start: this.nowDate,
              end: this.nowDate
            });
          }, 200);
        }
      });
      this.timeChange(this.nowDate, this.nowDate);
    },
    timeChange: function(start, end = "", isFive) {
      if (end.indexOf("23:59:59") > 0) {
      } else {
        end = end ? end + " 23:59:59" : "";
      }

      this.$emit("change", {
        start: start,
        end: end,
        isFive: isFive || false
      });
    },
    leftPad: function(d) {
      if (d < 10 && d >= 0) {
        return "0" + d;
      }
      return "" + d;
    },

  }
};
</script>
<style>
.select-day-of-five .five-day-warp {
  background: #eee;
  line-height: 28px;
  width: auto;
}
.select-day-of-five .five-day-warp span {
  width: 45px;
  text-align: center;
  display: inline-block;
  font-weight: bold;
}
.select-day-of-five .isChecked {
  color: white;
  background: #3b3f51;
}
.over-five {
  color: #659be0;
  width: 45px;
  display: inline-block;
  line-height: 26px;
  text-align: center;
  border: 1px solid #eee;
}
</style>
