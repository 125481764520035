<template>
    <div class="vue-attachment" :style="styleObjs">
        <div class="container-number-seal-bar">
            <i class="fa fa-angle-double-left"  @click="prevImage"></i>
            <i class="fa fa-plus-square-o"  @click="enlargeImage"></i>
            <i class="fa fa-minus-square-o" @click="shrinkImage"></i>
            <i class="fa fa-rotate-left" @click="rotateImage"></i>
            <i class="fa fa-angle-double-right" @click="nextImage"></i>
            <span class="container-sequence">{{activeIndex}}</span> / <span v-if="images">{{images.length}}</span>
            <i class="fa fa-download" style="margin-left: auto;" @click="downloadAttachment"></i>
        </div>
        <div class="container-number-seal-bar" v-if="isMobile">
            <span class="fa"  @click="moveImage('top',-1)">上移</span>
            <span class="fa"  @click="moveImage('top',1)">下移</span>
            <span class="fa" @click="moveImage('left',-1)">左移</span>
            <span class="fa" @click="moveImage('left',1)">右移</span>
        </div>

        <!-- 修改了一下判断条件 -->
        <!-- <div v-if="images.length>0 && images[activeIndex-1].imagePath.includes('pdf')" :class="{'clear container-number-seal-img':true, 'pdf-wrapper':ispdf}">
                <iframe :class="{'selected':activeIndex == (index + 1)}" v-show="activeIndex == (index + 1)" style="width:100%;height:100%" :ref="'images-' + index" v-for="(url,index) in images" :src="url.imagePath" frameborder="0">1</iframe>
        </div>
        <div ref="imagesBox" class="clear container-number-seal-img" v-else-if="images.length>0 && !images[activeIndex-1].imagePath.includes('pdf')">
            <img :class="{'selected':activeIndex == (index + 1)}" :style="activeStyle" :ref="'images-' + index" v-for="(img,index) in images" :src="img.imagePath">
        </div> -->
        <div v-if="ispdf" :class="{'clear container-number-seal-img':true, 'pdf-wrapper':ispdf}">
                <iframe :class="{'selected':activeIndex == (index + 1)}" v-show="activeIndex == (index + 1)" style="width:100%;height:100%" :ref="'images-' + index" v-for="(url,index) in images" :src="url" frameborder="0">1</iframe>
        </div>
        <div ref="imagesBox" class="clear container-number-seal-img" v-else-if="images && images.length > 0">
            <img :class="{'selected':activeIndex == (index + 1)}" :style="activeStyle" :ref="'images-' + index" v-for="(img,index) in images" :src="img.imagePath">
        </div>
        <div class="clear container-number-seal-img" style="line-height: 400px;" v-else>
            无上传照片
        </div>
    </div>
</template>
<script>
    const downLoad = (fileUrl) => {
        const URL = window.URL || window.webkitURL;
        function saveAs(blob, filename) {
            let type = blob.type;
            let force_saveable_type = 'application/octet-stream';
            if (type && type != force_saveable_type) { 
                let slice = blob.slice || blob.webkitSlice || blob.mozSlice;
                blob = slice.call(blob, 0, blob.size, force_saveable_type);
            }
            let url = URL.createObjectURL(blob);
            let save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
            save_link.href = url;
            save_link.download = filename;

            let event = new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window
            });
            save_link.dispatchEvent(event);
            URL.revokeObjectURL(url);
        }
        let oReq = new XMLHttpRequest();
        let URLToPDF = fileUrl;
        oReq.open("GET", URLToPDF, true);
        oReq.responseType = "blob";
        oReq.onload = function() {
            let file = new Blob([oReq.response], {
                type: 'application/pdf'
            });
            saveAs(file, fileUrl.split('/').slice(-1)[0]);
        };
        oReq.onerror = () => {
            window.open(fileUrl, '_blank')
        }
        oReq.send();
    }
    export default {
        name: 'dk-look-picture',
        data() {
            return {
                activeIndex: 1,
                activeStyle: {},
                DEG: 0
            };
        },
        props: {
            styleObjs: {
                default: {},
                type: Object
            },
            images: {
                default: [],
                type: Array
            },
            isMobile: {
                default: false,
                type: Boolean
            },
            ispdf: {
                default: false,
                type: Boolean
            },
            showdownload:{
                default: false,
                type: Boolean
            }
        },
        created: function() {
            this.activeStyle = this.getActiveStyle();
        },
        mounted: function() {
            this.imageDraggable();
        },
        watch:{
            images:function(){
                this.activeIndex = 1;
                this.$nextTick(function(){
                    this.imageDraggable()
                })
            }
        },
        methods: {
            imageDraggable:function(){
                $(this.$refs.imagesBox).find('img').draggable();
                console.log('--->', this.images)
            },
            getActiveStyle: function() {
                return {
                    "height": "",
                    "marginTop": "",
                    "transform": "",
                    "left": "0px",
                    "top": "0px"
                };
            },
            prevImage: function() {
                if(!this.images.length) return;
                this.activeIndex -= 1;
                if(this.activeIndex < 1) {
                    this.activeIndex = this.images.length;
                }
                this.$emit('previmage', {index: this.activeIndex});
            },
            nextImage: function() {
                if(!this.images.length) return;
                this.activeIndex += 1;
                if (this.activeIndex > this.images.length) {
                    this.activeIndex = 1;
                }
                this.$emit('nextimage', {index: this.activeIndex})
            },
            enlargeImage: function() {
                if(!this.images.length) return;
                var imagesBox = this.$refs.imagesBox;
                var img = this.$refs['images-' + (this.activeIndex - 1)][0];
                $(imagesBox).find('.selected').css('height',(img.clientHeight + 200) + 'px');
                this.$nextTick(this.resetImage)
            },
            shrinkImage: function() {
                if (this.images.length <= 0) return;
                var imagesBox = this.$refs.imagesBox;
                var img = this.$refs['images-' + (this.activeIndex - 1)][0];
                if (img.clientHeight > 400) {
                    $(imagesBox).find('.selected').css('height',(img.clientHeight - 200) + 'px');
                    this.$nextTick(this.resetImage)
                }
            },
            resetImage: function() {
                if (this.images.length <= 0) return;
                var imagesBox = this.$refs.imagesBox;
                var img = this.$refs['images-' + (this.activeIndex - 1)][0];
                var height = 200 - img.clientHeight / 2;
                $(imagesBox).find('.selected').css({'margin-top':height + 'px','left': 0, 'top': 0 });
            },
            rotateImage: function() {
                if (this.images.length <= 0) return;
                this.DEG = this.DEG + 90;
                if (this.DEG >= 360) {
                    this.DEG = 0
                }
                var imagesBox = this.$refs.imagesBox;
                $(imagesBox).find('.selected').css({'transform':'rotate(' + this.DEG + 'deg)','left': 0, 'top': 0 });
                this.$nextTick(this.resetImage)
            },
            moveImage: function(key,val){
                if (this.images.length <= 0) return;
                var img = this.$refs["images-" + (this.activeIndex - 1)][0];
                img.style[key] = parseInt(img.style[key]) + 20*val + 'px';
            },
            downloadAttachment: function() {
                console.log(this.images)
                console.log(this.images[this.activeIndex - 1])
                downLoad(this.images[this.activeIndex - 1].imagePath || this.images[this.activeIndex - 1]) 
            },
        }
    }
</script>
<style scoped>
    .container-number-seal-bar{
        position: relative;
    }
    .fa-download{
        position: absolute;
        right: 0;
        top: 0;
    }
</style>

