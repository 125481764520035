<template>
    <div class="input-group">
        <label class="control-label" :style="{width:labelWidth + 'px', 'text-align': Align}"><i v-if="asterisk" class="fa fa-asterisk"></i>{{label}}</label>
        <div class="input-date" :style="{width:divWidth + 'px', display: inputDisplay}">
            <input style="text-align:center;" :style="{width:inputWidth + 'px'}" class="form-control" :disabled="startDisabled" type="text" :esapp-id="'start-' + id"  autocomplete ="off" />
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                id: Math.ceil(new Date().getTime() +  Math.random()*100), // 修复当两个时间组件放一起时会出现相同id的问题
                datePulg:null,
            };
        },
        props: {
            label:{
                type:String,
                default:"做箱时间"
            },
            labelWidth:{
                type:Number,
                default:80
            },
            inputWidth:{
                type:Number,
                default:150
            },
            divWidth:{
                type:Number,
                default:150
            },
            start:{
                type:String,
                default:""
            },
            startDisabled:{
                type:Boolean,
                default:false
            },
            asterisk:{
                type:Boolean,
                default:false
            },
            value: {
                type: String,
                default: ""
            },
            init: {
                type: Boolean,
                default: true
            },
            trace: {
                type: Boolean,
                default: false
            },
            Align: {
                type: String,
                default: 'center'
            },
            inputDisplay: {
                type: String,
                default: 'block'
            }
        },
        watch: {
            value(val){
                console.log(val)
                this.setValue({start:val});
            }
        },
        mounted: function() {
            //因为需要dom渲染完在初始化日期插件
            if (!this.trace) {
                this.initPlug();
            }
            this.setValue({start: this.value})
        },
        // watch:{
        //     value:function () {
        //         this.setValue(this.value)
        //     }
        // },
        methods: {
            initPlug: function() {
                var that = this;
                // esWidget 需要引入widgit.js文件
                var datePulg = esWidget.esDateToDate({
                    startId: "start-" + this.id,
                    format: "yyyy-mm-dd",
                    getAddTime: true,
                    time: false,
                    start_event_chage: function(res) {
                        // if(res){
                        //     res += " 00:00:00";
                        // }
        
                        that.start = res;
                        if(that.init) {
                            that.timeChanged();
                        }
                    },
                    end_event_chage: function(res) {
                        that.end = res;
                        that.timeChanged();
                    }
                });
                datePulg.setValue({
                    startDate:this.start
                });
                that.datePulg = datePulg;
                // esWidget 需要引入widgit.js文件
            },
            clearValue:function(){
                this.datePulg.clearValue();
            },
            setValue:function(val){
                var date = {
                    startDate:""
                };
                if(val &&val.start){
                    date = {
                        startDate:val.start.split(' ')[1] ?val.start.split(' ')[0] :val.start
                    }
                }else if(val){
                    date = {
                        startDate:''
                    }
                }
                this.datePulg.setValue(date);
            },
            timeChanged: function(whichTime, time) {
                this.$emit("change",{
                    start:this.start
                })
            }
        }
    };
</script>