<template>
    <div class="input-group">
        <label v-if="showLabel" class="control-label " :style="{ width: labelWidth + 'px', 'text-align': Align }"
            :class="required ? 'required-label' : ''">
            <i v-if="asterisk" class="fa fa-asterisk"></i>{{ label }}</label>
        <div :style="{ width: inputWrapWidth + 'px' }">
            <input class="form-control" :style="{ width: inputWidth + 'px' }" ref="input" :disabled="disabled"
                :placeholder="placeholder" v-model.trim="value" @blur="blur" @input="changeValue" @focus="focus"
                @keyup.enter="keyup" :type='type' :min="type=='number'?0:''" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    props: {
        placeholder: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: "提单号"
        },
        labelWidth: {
            type: Number,
            default: 80
        },
        inputWidth: {
            type: Number,
            default: 150
        },
        inputWrapWidth: {
            type: Number,
            default: 150
        },
        value: {
            type: null,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        asterisk: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: "text"
        },
        Align: {
            type: String,
            default: 'center'
        },
        required: {
            type: Boolean,
            default: false
        },
        showLabel: {
            type: Boolean,
            default: true
        },
    },
    methods: {
        changeValue: function () {
            this.$emit("input", this.value);
        },
        blur: function () {
            this.$emit("blur", this.value);
        },
        focus: function () {
            this.$emit("focus", this.value)
        },
        keyup: function () {
            this.$emit("keyup", this.value)
        },
        blurInput() { // 取消input焦点
            this.$refs.input.blur()
        },
    }
};
</script>
<style>
.required-label::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
}
</style>