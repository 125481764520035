
<template>
        <div style="display:inline-block;margin-left: 5px;">
            <span style="color:#337ab7;cursor: pointer;" v-if="value" @click="phoneClick" class="fa fa-phone"></span>
        </div>
</template>

<script>
export default {
    name: "dk-icon-phone",
    data () {
        return {
            isFats: false,
            visible: false,
            finishTime: "",
        };
    },
    props: {
        value: {
            type: null,
            default: ""
        },
        id: {
            type: null,
            default: ""
        }
    },
    mounted () {
        if (window.location.origin.indexOf('fat') !== -1 || window.location.origin.indexOf('local') !== -1) {
            this.isFats = true
        } else if (window.location.origin.indexOf('carrierglobe') !== -1) {
            this.isFats = false
        }
    },
    methods: {
        phoneClick () {
            let that = this
            if (!this.value) {
                return;
            }
            const params = {
                userId: this.id,
                mobilePhone: this.value
            }
            _.util.ajax_get('/driver/rest/detail',params,res => {
                console.log(res)
                if(res.success && res.data && res.data.value){
                    const data = JSON.parse( res.data.value)
                    if (data.driverRest) {
                        that.finishTime = that.formatTime(data.restTime)
                        _.ui.confirm({
                            innerHTML:'当前司机正在休息，剩余'+that.finishTime+'。为避免司机疲劳驾驶，非必要情况下请勿打扰。',
                            positiveBtnMsg: '仍要呼叫',
                            cancelBtnMsg: '暂不呼叫',
                            success: function(){
                                that.handleCalled()
                            }
                        })
                    }else{
                        that.handleCalled()
                    }
                }else{
                    that.handleCalled()
                }
            })

        },
        handleCalled(){
            if(this.isFats) {
                window.open(`callcentertest://?phone=${this.value}`, "_blank");
            } else {
                window.open(`callcenter://?phone=${this.value}`, "_blank");
            }
        },

        formatTime(time){
            const h = Math.floor(time / 3600)
            const m = Math.floor((time % 3600) / 60)
            const s = time % 60
            return `${h}小时${m}分钟${s}秒`
        }
    }
};
</script>