<template>
  <div class="dk-select input-group ">
    <label :style="{ width: labelWidth + 'px', 'text-align': Align }" class="control-label"
      :class="required ? 'required-label' : ''">
      <i v-if="isMustFill" class="fa fa-asterisk"></i>
      {{ title }}
    </label>
    <div :style="{ width: selectWidth + 'px' }">
      <select class="form-control" v-model="value" @change="selectChange" :style="{ width: inputWidth + 'px' }"
        :disabled="isDisabled">
        <option v-for="item in viewData" :value="item.value" :key="item.value">{{ item.name }}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dk-select',
  data() {
    return {}
  },
  props: {
    value: {
      type: null,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    viewData: {
      type: Array,
      default: []
    },
    selectWidth: {
      type: Number,
      default: 150
    },
    labelWidth: {
      type: Number,
      default: 85
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isMustFill: {
      type: Boolean,
      default: false
    },
    Align: {
      type: String,
      default: 'center'
    },
    inputWidth: {
      type: Number,
      default: 150
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$emit('mounted', this.value)
  },
  methods: {
    selectChange: function () {
      this.$emit('input', this.value)
    },
    reload:function(){
      this.$forceUpdate()
    }
  }
}
</script>
<style scoped>
.control-label {
  text-align: center;
  font-style: normal;
  padding-right: 5px;
  /* padding-top: 5px; */
}
.required-label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
</style>